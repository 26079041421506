.title {
  vertical-align: middle;
  color: #FF7F29;
  font-size: 16px;
  box-sizing: border-box;
  text-transform: uppercase;
  padding-bottom: 16px;
}

.borderButton {
  display: block;
  text-transform: none;
  color: #8dc63f;
  border: 1px solid #8dc63f;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  margin: 10px 0;
}

.paymentPage {
  padding: 10px 0 70px;
  font-size: 14px;
  background-color: #EFEFEF;
  height: 100vh;
}

.paymentForm {
  padding: 16px;
}

@media (orientation: landscape) {
  .paymentPage {
    height: 350px;
  }
}
