.item {
  padding: 3px 5px;
  position: relative;
}

.itemContainer {
  display: flex;
  width: 100%;
  background-color: #fff;
}

.itemPhotoWrapper {
  padding: 7px;
  height: 100px;
  position: relative;
}

.itemLink {
  text-decoration: none;
}

.itemBadgeLifted,
.itemBadgePremium {
  position: absolute;
  color: white;
  bottom: 7px;
  text-align: center;
  width: 100px;
  padding: 3px 0;
  font-size: 14px;
}

.itemBadgePremium {
  background: #FF7F29;
}

.itemBadgeLifted {
  background: #8DC63F;
}
