.title {
  width: 100%;
  padding: 0 20px;
  line-height: 28px;
  background-color: #EFEFEF;
  font-size: 11px;
  color: #6d6d6d;
  font-weight: 400;
  text-transform: uppercase;
  box-sizing: border-box;
}

.content {
  padding: 20px 0;
}