.react-autosuggest__input {
  font-size: 15px;
  display: block;
  width: calc(100% - 32px);
  padding: 10px;
  box-sizing: border-box;
  outline: none;
  color: #FF7F29;
  border: 1px solid #dddddd;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  margin: 16px 16px 0 16px;
}

.react-autosuggest__input:focus {
  color: #252525;
  border: 1px solid #dddddd;
}

.react-autosuggest__suggestions {
  padding: 0;
  margin: 0 0 10px 16px;
  max-height: 258px;
}

.react-autosuggest__suggestion {
  list-style-type: none;
  font-size: 15px;
  font-weight: 400;
  padding: 16px 0;
  border-bottom: 1px solid #dddddd;
}

.react-autosuggest__suggestion--focused {

}

.react-autosuggest__suggestions-section {
  padding: 0;
}

.react-autosuggest__suggestions-section-name {

}

.react-autosuggest__suggestions-section-suggestions {

}

.react-autosuggest__suggestion--focused {
  background-color: rgba(150, 150, 150, 0.2);
}

.clearButton {
  position: absolute;
  right: 25px;
  top: 69px;
}
