.item {
  padding: 10px 20px;
  margin-right: 4px;
  margin-left: 4px;
  margin-bottom: 8px;
  font-size: 15px;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  flex-grow: 1;
  background: #EEEEEE;
  text-align: center;
}

.checked {
  background: #FF7F29;
  border: 1px solid #FF7F29;
  color: white;
}

.label {
  font-size: 12px;
  color: #757575;
  padding: 8px 16px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
}