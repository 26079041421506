.paymentsPeriod {
  font-size: 16px;
  padding: 5px 0;
}

.debit {
  font-size: 16px;
  color: #F44336;
  padding: 5px 0;
}

.credit {
  font-size: 16px;
  color: #8dc63f;
  padding: 5px 0;
}

.paymentsInput {
  position: absolute;
  right: 0;
  opacity: 0;
  z-index: 2;
}

.select {
  height: 40px;
  background-color: transparent;
  line-height: 40px;
  border-bottom: 1px solid #f8f8f8;
}
.select > [class*="label"] {
  padding: 0 0 0 20px;
}
.select > [class*="wrapper"] {
  padding: 0 20px 0 0;
}
.select [class*="selected"] {
  margin: 0 20px 0 0;
  padding: 0;
  max-width: 120px;
}
.select [class*="material-icons"] {
  top: 9px !important;
  right: 15px !important;
}

.item {
  padding: 0 10px;
  margin: 10px 0;
}

.params {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0;
}

.label {
  font-size: 12px;
  color: #323232;
  margin-bottom: 4px;
  margin-top: 4px;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.sum {
  color: #8DC63F;
}

.costs {
  color: #F44336;
}

.date {
  color: #8DC63F;
}

.description {
  display: flex;
  align-items: flex-end;
  min-height: 36px;
  font-size: 14px;
  color: #808080;
  padding: 10px;
}

.paramsItem {

}

.button {
  composes: button from './UserRegisterRestore.css';
}

.modalContent {
  display: flex;
  min-height: 100%;
  padding: 0 10px;
  align-items: center;
}

.modalClose {
  width: 28px;
  height: 28px;
  position: fixed;
  right: 15px;
  top: 12px;
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
}

.modalClose a {
  display: block;
  position: relative;
  top: 2px;
}

.modalContent .description {
  display: block;
  min-height: 0;
}
