.linkToggle {
  padding: 0 0 10px;
  display: block;
  text-decoration: none;
  color: #757575;
}

.toggleContainer {
  background-color: #EEEEEE;
  padding: 2px;
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  height: 34px;
  justify-content: center;
}

.toggleButtonActive {
  flex-grow: 1;
  line-height: 34px;
  background-color: #FF7F29;
  border-radius: 3px;
  color: white;
}
.toggleButton{
  flex-grow: 1;
  line-height: 34px;
}