.root {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.SliderContainer {
  max-height: 350px;
  width: 100vw;
  height: 56.25vw;
  background-color: #EEEEEE;
}

.Slide {
  max-height: 350px !important;
  width: 100vw;
  height: 56.25vw;
  background-color: #EEEEEE;
  object-fit: cover !important;
}

@media (orientation: landscape) {
  .SliderContainer {
    height: 23.125vw;
  }
  .Slide {
    height: 23.125vw;
  }
}

:global
.slick-slider {
  margin-bottom: 0px;
}

.Image {
  display: block;
  height: 100%;
  width: auto;
}

.sliderWrapper {
  position: relative;
}

.photoCounter {
  position: absolute;
  left: 10px;
  bottom: 15px;
  background: rgba(0, 0, 0, 0.3);
  padding: 3px 9px;
  border-radius: 2px;
  z-index: 2;
  font-weight: bold;
  font-size: 12px;
  color: #FAFAFA;
  transform: translate3d(0,0,0);
}

.badge {
  pointer-events: none;
  display: inline-block;
  width: 22px;
  margin: 0 4px;
}

.badgeContainer {
  position: absolute;
  right: 9px;
  top: 10px;
}

.info {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  color: black;
  padding: 5px 15px;
  background: #EEEEEE;
}

.promo {
  background: #F1FDDF;
}

.special {
  background: #FFFEED;
}

.price {
  font-size: 16px;
  font-weight: 700;
  padding: 6px 0;
}

.price svg {
  position: relative;
  top: 2px;
}

.placeholder {
  width: 100%;
  height: 100%;
  line-height: 100%;
  color: #aaa;
  background: white;
  text-align: center;
  vertical-align: middle;
  font-size: 20px
}

.complexLink a {
  font-size: 14px;
  color: #0168BB;
  font-weight: normal;
  padding-bottom: 5px;
}

.complexLink {
  padding-bottom: 5px;
}

.area {
  font-weight: normal;
  font-size: 14px;
  padding-bottom: 5px;
}

.address {
  font-weight: normal;
  font-size: 15px;
  padding-bottom: 5px;
}

.params {
  font-weight: normal;
  font-size: 14px;
  color: #757575;
}

.square {
  display: inline-block;
}

.floors {
  display: inline-block;
}

.devider {
  padding: 0 8px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.trusted {
  position: absolute;
  top: 10px;
  left: 11px;
  z-index: 2;
  transform: translate3d(0,0,0);
}

.buttonFavoriteContainer {
  position: absolute;
  right: 5px;
  top: 3px;
  z-index: 2;
  text-align: center;
  transform: translate3d(0,0,0);
}
