.root {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.root .defaultIcon {
  padding: 17px 16px 14px 10px;
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0 16px 16px;
  font-size: 15px;
  width: 100%;
}

.value {
  color: #9E9E9E;
}

.selected {
  color: #FF7F29;
}