.root {

}

.logo {
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 56px;
  min-height: 56px;
}

.logoText {
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  padding: 0 16px 14px;
  margin-top: -5px;
}

.balloonText {
  font-size: 10px !important;
  background-color: #006CFD;
  border-radius: 2px;
  padding: 1px 3px;
  flex: none !important;
  margin-left: 4px;
}

.commonListItemText {
  flex: 0 1 auto !important;
}

.selectedText {
  color: #94C133;
}