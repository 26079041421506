.base {
  width: 100%;
  height: 100%;
  position: relative;
}

.body {
  width: 100%;
  position: absolute;
  top: 50%;
  text-align: center;
  color: white;
  transform: translateY(-50%);
}