.paymentsPeriod {
  font-size: 16px;
  padding: 5px 0;
}

.debit {
  font-size: 16px;
  color: #F44336;
  padding: 5px 0;
}

.credit {
  font-size: 16px;
  color: #8dc63f;
  padding: 5px 0;
}

.paymentsInput {
  position: absolute;
  right: 0;
  opacity: 0;
  z-index: 2;
}

.select {
  height: 40px;
  background-color: transparent;
  line-height: 40px;
  border-bottom: 1px solid #f8f8f8;
}
.select > [class*="label"] {
  padding: 0 0 0 20px;
}
.select > [class*="wrapper"] {
  padding: 0 20px 0 0;
}
.select [class*="selected"] {
  margin: 0 20px 0 0;
  padding: 0;
  max-width: 120px;
}
.select [class*="material-icons"] {
  top: 9px !important;
  right: 15px !important;
}

.item {
  padding: 0 10px;
  margin: 10px 0;
}

.card {
  width: 100%;
  min-height: 85px;
}

.params {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0;
}

.label {
  font-size: 12px;
  color: #323232;
  margin-bottom: 4px;
  margin-top: 4px;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.number {
  color: #8DC63F;
}

.sum {
  padding: 10px 0 0 10px;
}

.costs {
  color: #F44336;
}

.date {
  color: #8DC63F;
}

.description {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  color: #808080;
  padding: 0 10px 10px 10px;
}

.button {
  composes: button from './UserRegisterRestore.css';
}

.createInvoiceBtn {
    color: white;
    border: 0;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    font-weight: 300;
    display: block;
    background-color: #FF7F29;
    text-decoration: none;
}

.price {
  font-size: 24px;
  padding-top: 3px;
  margin-bottom: 14px;
}

.contractor {
  font-size: 13px;
  color: #757575;
}

.btn-download {
  display: inline-block;
  padding: 7px 7px 4px 7px;
  background: #eee;
}