.root {
  box-sizing: border-box;
  margin: 0 16px;
}

.field {
  display: inline-block;
  width: auto;
  box-sizing: border-box;
  position: relative;
  flex-grow: 1;
}

.label {
  position: absolute;
  top: -5px;
  left: 5px;
  background-color: white;
  text-align: center;
  font-size: 0.7em;
  padding: 0 5px;
  color: #757575;
}

.measureLabel {
  position: absolute;
  top: -5px;
  right: 5px;
  background-color: white;
  padding: 0 5px;
  text-align: center;
  font-size: 0.7em;
  color: #757575;
}

.input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 10px 8px 30px;
  text-align: right;
  outline: none;
  color: #FF7F29;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
}

.input:focus {
  color: #252525;
}

.placeholder {
  position: absolute;
  top: 10px;
  left: 5px;
  color: #9E9E9E;
  font-size: 15px;
}

.min input {
  border-radius: 4px 0 0 4px;
}

.max input {
  border-radius: 0 4px 4px 0;
  border-left: none;
}

.mainLabel {
  font-size: 12px;
  line-height: 14px;
  color: #757575;
  padding: 8px 0 0;
}

.fieldsWrapper {
  display: flex;
  padding: 8px 0;
}