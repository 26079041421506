.title {
  font-size: 22px;
  font-weight: normal;
  margin: 20px 0;
}
.success {
  color: #8dc63f;
  font-size: 16px;
}
.fail {
  color: #F44336;
}
.button {
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  text-align: center;
  padding: 15px;
  background-color: #FF7F29;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;
}