.sticky {
    position: fixed;
    bottom: 0;
    z-index: 6;
    left: 0;
    right: 0;
}

@media (orientation: portrait) {
    .adaptive {
        right: 0;
    }
    .adaptive a{
        font-size: 20px;
    }
}
@media (orientation: landscape) {
    .adaptive {
        right: inherit;
    }
    .adaptive a {
        font-size: 15px;
    }
}