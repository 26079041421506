.root {
  width: 100%;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 4px;
}

.rootError{
  width: 100%;
  border: 1px solid #F44336;
  box-sizing: border-box;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: #7e7e7e;
  border: none;
  border-radius: 4px;
}

.input:focus {
  outline: none;
  color: #252525;
}

.errorText{
  color: #F44336;
  padding: 3px;
}