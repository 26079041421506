.base {
  padding: 16px;
}

.base b {
  margin: 5px 0;
  display: inline-block;
  font-weight: 500;
}

.userTariff {
  background-color: #EFEFEF;
  height: 100vh;
}

.tariffName {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 8px;
}

.tariffDescription {
  color: #212121;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  white-space: pre-line;
}

.tableHeader {
  padding: 24px 16px 12px;
}

.tariffTable table {
  width: 100%;
}

.tariffTable .subTable > tr td,
.tariffTable .subTable > tr th {
  border-right: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  min-height: 16px;
  height: 16px;
  white-space: nowrap;
}

.tariffTable .subTable > tr th{
  border-top: 1px solid #D8D8D8;
  color: #757575;
  font-size: 14px;
}

.tariffTable .subTable:first-child > tr td{
  border-top: 1px solid #D8D8D8;
}

.tariffTable > table > tr > td:last-child {
  border-right: none;
}

.tariffTable table > tr > td:last-child > table tr td,
.tariffTable table > tr > td:last-child > table tr th {
  border-right: none;
}

.tariffTable table > tr > td {
  padding: 0px;
  color: #212121;
}

.hint {
  font-size: 12px;
  white-space: normal;
}

.tariffTable > .subTable > tr > .smTableHeader {
  white-space: normal;
}

