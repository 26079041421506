.bannerWrapper {
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: center;
}

.header {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: #152242;
    margin-bottom: 17px;
}

.text {
    margin: 0 0 90px 0;
    font-size: 14px;
    line-height: 22px;
    color: #152242;
}

.link {
    margin: 25px auto 0;
    background: #0468FF;
    color: white;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
    text-decoration: none;
    padding: 13px 74px;
    display: inline-block;
}

.link:hover {
    background: #005de9;
 }