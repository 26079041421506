.panelTitle {
  font-size: 11px;
  background-color: #EFEFEF;
  padding-left: 16px;
}

.firstPanelTitle {
  padding-top: 10px;
  background-color: #EFEFEF;
  border-top: 1px solid #f8f8f8;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 8%);
  padding-left: 16px;
}

.userAccount {
  background-color: #EFEFEF;
  height: 100vh;
  padding-bottom: 70px;
}

.userProfile {
  padding-top: 10px;
  background-color: #EFEFEF;
  height: 100vh;
}

.userBalance {
  padding-top: 10px;
  background-color: #EFEFEF;
  height: 100vh;
}

.link {
  display: block;
  padding: 0;
  color: #252525;
  text-decoration: none;
  font-size: 15px;
}

.arrowLink {
  position: relative;
  display: block;
  padding: 16px;
  color: #212121;
  text-decoration: none;
  font-size: 15px;
}

.arrowLink:after {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -6px;
  border-top: 2px solid #D8D8D8;
  border-right: 2px solid #D8D8D8;
  transform: rotate(45deg);
}

.green {
  color: #8dc63f;
}

.orange {
  color: #FF7F29;
}

.filler {

}

.fillerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (orientation: landscape) {
  .userAccount {
    height: 100%;
  }
  .userProfile {
    height: 100%;
    padding-bottom: 25px;
  }
  .userBalance {
    height: 100%;
    padding-bottom: 25px;
  }
}
