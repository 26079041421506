.title {
  border-top: solid 1px #D8D8D8;
  padding: 16px;
  font-weight: 500;
  font-size: 18px;
  color: #212121;
}

.savedSearchItem {
  padding: 8px 11px 8px 16px;
}

.savedSearchLink {
  font-size: 16px;
  color: #0168BB;
  text-decoration-line: underline;
}

.savedSearchType {
  font-size: 14px;
  color: #212121;
}

.savedSearchField {
  padding: 3px 0;
}

.deleteButton {
  float: right;
}

.emptyList {
  font-size: 15px;
  margin: 16px;
  color: #757575;
  text-align: center;
}
