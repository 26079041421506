.list {
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    max-height: 235px;
    min-height: 112px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 1;
}
.item {
    margin: 5px;
    display: flex;
    background-color: #ffffff;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    overflow: hidden
}