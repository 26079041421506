.root {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  font-size: 15px;
}
.name {

}
.value {
  color: #FF7F29;
}
