.root {
  position: relative;
  box-sizing: border-box;
  border-bottom: 4px solid white;
  height: 160px;
}

.image {
  background-size: cover !important;
  background-position: center !important;
  position: absolute;
  image-rendering: pixelated;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.photoCounter {
  position: absolute;
  right: 15px;
  top: 15px;
  background: rgba(20, 20, 20, 0.5);
  color: #FFFFFF;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 2px;
  z-index: 1;
}

.priceInfo {
  height: 50px;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  color: white;
  text-align: center;
  line-height: 50px;
  left: 0;
  font-size: 18px;
  bottom: 0;
}

.infoTable {
  width: 100%;
}

.infoTable tr {
  line-height: 20px;
  border-bottom: 1px solid #f5f5f5;
}

.infoTable tr:last-child {
  border-bottom: none;
}

.infoTable td {
  padding: 8px 0;
}

.paramValue {
  color: #8dc63f;
  text-align: right;
}

.panelTitle {
  margin: 0;
  text-align: center;
}

.price {
  color: #8dc63f;
  font-size: 22px;
}

.priceSquare {
  color: #666;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
}

.mainInfo {
  font-size: 16px;
  padding: 15px;
}

.mainInfoRow {
  background-color: #f8f8f8;
}

.slider {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.premiumBadge {
  position: absolute;
  top: 60px;
  left: 12px;
  background: #FF7F29;
  color: white;
  padding: 5px 8px;
  font-size: 14px;
}

.arrow {
  position: relative;
  padding-right: 15px;
}

.arrow:before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  border-top: 1px solid #252525;
  border-right: 1px solid #252525;
  transform: rotate(45deg);
}

.apartments {

}
