.root {
  list-style-type: none;
  margin: 0; 
  padding: 0; 
  background: '#f9f9f9';
}

.item {
  padding: 3px 5px;
  position: relative;
}

.itemContainer {
  display: flex;
  width: 100%;
  background-color: #fff;
}

.itemPhotoWrapper {
  padding: 7px;
  height: 100px;
  position: relative;
}

