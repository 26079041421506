.carousel {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.wrapper {
    display: flex;
    width: 100%;
    position: relative;
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
}

.item {
    width: 100%;
    /*overflow: hidden;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.img {
    width: 100%;
    display: block;
}
