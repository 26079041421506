.container {
  display: flex;
  border-radius: 4px;
  background-color: #333333;
  color: #fff;
  padding: 6px;
  max-width: 225px;
  margin: 0 10px;
}

.icon {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.text {
  color: #fff;
  font-size: 12px;
}