.root {
  height: 50px;
  background-color: #fff;
  line-height: 50px;
  font-size: 16px;
  font-weight: 400;
  display: table;
  width: 100%;
  position: relative;
  border-radius: 3px;
}

.element {
  /*color: transparent;*/
  /*background: transparent;*/
  /*appearance: none;*/
  /*border: none;*/
  /*outline: none;*/
  /*position: absolute;*/
  /*left: -100000px;*/

  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0
}

.selected {
  font-size: 15px;
  color: #FF7F29;
  margin-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  float: right;
}

.label {
  font-size: 15px;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 10px;
  vertical-align: middle;
  display: table-cell;
}

.wrapper {
  max-height: 20px;
  padding-right: 16px;
  display: table-cell;
  position: relative;
  text-align: right;
  overflow: hidden;
}

.wrapper span {
  color: #9d9d9d;
}

.value {
  padding-right: 18px;
  max-width: 20px;
  color: #8dc63f;
}