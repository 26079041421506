.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #D8D8D8;
  font-size: 15px;
  min-height: 41px;
  background-color: #fff;
}

.headerName {
  flex-grow: 1;
  text-align: center;
  padding: 12px 0 10px 0;
}

.headerClose {
  padding: 11px 13px 0 0;
}

.saveButton {
  color: white;
  border: 0;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  padding: 15px;
  background-color: #FF7F29;
  width: calc(100% - 32px);
  border-radius: 4px;
  margin: 10px auto;
  min-height: 48px;
}

.secondSaveButton {
  color: #202020;
  border: solid 1px #757575;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  padding: 15px;
  background-color: #fff;
  width: calc(100% - 32px);
  border-radius: 4px;
  margin: 10px auto;
  min-height: 48px;
}

.orangeSecondSaveButton {
  color: #FF7F29;
  border: solid 1px #FF7F29;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  padding: 15px;
  background-color: #fff;
  width: calc(100% - 32px);
  border-radius: 4px;
  margin: 10px auto;
  min-height: 48px;
}

.description {
  font-size: 15px;
  margin: 16px;
  color: #757575;
  text-align: center;
}

.descriptionRow {
  padding: 15px 0;
}

.timerDescription {
  font-size: 13px;
  color: #757575;
  text-align: center;
  margin: -5px 0 7px;
}

.inlineButtons {
  display: flex;
  flex-direction: row;
  margin: 16px;
  justify-content: space-between;
}

.inlineButtons .saveButton {
  width: auto;
  margin-right: 10px;
  flex: 1;
}

.inlineButtons .secondSaveButton {
  width: auto;
  flex: 2;
}

.textFieldWrapper {
  margin: 10px 0 10px 16px;
}

.unionModalBody {
  margin: 16px;
}

.unionHeaderContent {
  font-size: 24px;
  font-weight: bold;
  margin: 16px 0 8px;

}

.unionBanner {
  width: 100%;
}

.unionDescription {
  font-size: 17px;
  color: #212121;
  text-align: left;
}
