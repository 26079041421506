.root {
  text-transform: uppercase;
  color: white;
  border: 0;
  text-align: center;
  font-size: 18px;
  padding: 15px;
  background-color: #FF7F29;
  width: 100%;
  border-radius: 4px;
}