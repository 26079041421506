.linkToggle {
  padding: 0 0 10px;
  display: block;
  text-decoration: none;
  color: #757575;
  text-align: center;
}

.toggleContainer {
  background-color: #EEEEEE;
  padding: 2px;
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  height: 34px;
  justify-content: center;
}

.toggleButtonActive {
  flex-grow: 1;
  line-height: 34px;
  background-color: #FF7F29;
  border-radius: 3px;
  color: white;
}

.toggleButton {
  flex-grow: 1;
  line-height: 34px;
}

.counter {
  border-top: 1px solid #D8D8D8;
  padding: 9px 8px 10px;
  font-size: 12px;
  color: #9E9E9E;
  background-color:#F5F6F8;
  text-align: center;
}

.filterButtonContainer {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 135px;
  z-index: 3;
  text-decoration: none;
}

.filterButton {
  background: #fff;
  border: 1px solid #FF7F29;
  border-radius: 16px;
  text-align: center;
  color: #FF7F29;
  padding: 4px 4px 7px 8px;
}

.filterIcon {
  position: relative;
  top: 3px;
  right: 4px;
}