.root {
  position: relative;
  box-sizing: border-box;
  border-bottom: 4px solid white;
  height: 170px;
}

.image {
  background-size: cover !important;
  background-position: center !important;
  position: absolute;
  image-rendering: pixelated;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.photoCounter {
  position: absolute;
  right: 15px;
  top: 15px;
  background: rgba(20, 20, 20, 0.5);
  color: #FFFFFF;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 2px;
  z-index: 1;
  pointer-events: none;
}

.priceInfo {
  height: 50px;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  color: white;
  text-align: center;
  line-height: 50px;
  left: 0;
  font-size: 18px;
  bottom: 0;
}

.infoTable {
  width: 100%;
}

.infoTable tr {
  line-height: 20px;
  border-bottom: 1px solid #f5f5f5;
}

.infoTable tr:last-child {
  border-bottom: none;
}

.infoTable td {
  padding: 8px 0;
}
.paramName {
    color: #7a7a7a;
}
.paramValue {
  color: #252525;
  text-align: right;
}

.panelTitle {
  margin: 0;
  text-align: center;
}

.price {
  color: #8dc63f;
  font-size: 26px;
}

.priceSquare {
  color: #666;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
}

.mainInfo {
  font-size: 16px;
  padding: 5px 15px;
}

.mainInfoRow {
  background-color: #f8f8f8;
}

.slider {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.premiumBadge {
  position: absolute;
  top: 64px;
  left: 15px;
  width: 20px;
  pointer-events: none;
}

/* style for contacts page view */
.contactSellerBtn {
    color: white;
    border: 0;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    font-weight: 300;
    display: block;
    background-color: #FF7F29;
    text-decoration: none;
}
.contactsBlock {
    font-size: 14px;
    margin: 0 20px;
    color: #252525;
}
.contactsBlock a{
    color: #8dc63f;
    text-decoration: none;
}
.contactsBlock div div:first-child{
    float: left;
}
.contactsBlock div div:last-child {
    text-align: right;
    color: #8dc63f;
}
.contactsBlock div {
    padding: 5px 0;
}
.callTrackingDescription span {
  text-align: left;
  color: #757575;
}
.contactsBlock .callTrackingDescription {
  padding: 10px 0;
}
.objMainInfo {
    margin: 5px 20px;
}
.objMainInfo div {
    font-size: 14px;
    color: #252525;
    padding: 3px 0;
 }
.objMainInfo div:first-child {
    color: #8dc63f;
    font-size: 16px;
}
.objMainInfo div:first-letter {
    text-transform: capitalize;
}

.pager{
    text-align: center;
    height: 40px;
    margin-top: 12px;
}
.pager a{
    padding: 10px;
    color: #8dc63f;
    text-decoration: none;
    border-radius: 5px;
    border: 1px solid #a7e342;
}
.pager a:first-child{
    margin-right: 10px;
}

.sticky {
    position: fixed;
    bottom: 0;
    z-index: 6;
    left: 0;
    right: 0;
}

@media (orientation: portrait) {
    .adaptive {
        right: 0;
    }
    .adaptive a{
        font-size: 20px;
    }
}
@media (orientation: landscape) {
    .adaptive {
        right: inherit;
    }
    .adaptive a {
        font-size: 15px;
    }
}

.hide {
    display: none;
}