.titleSuccess {
  text-align: center;
  vertical-align: middle;
  height: 40px;
  color: #8dc63f;
  font-size: 16px;
  box-sizing: border-box;
  margin: 20px 0;
  text-transform: none;
}

.button {
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  border: 0;
  text-align: center;
  font-size: 16px;
  padding: 15px;
  background-color: #FF7F29;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
}

.restore,
.register {
  padding: 10px;
  font-size: 14px;
}

.phoneBlock {
  width: 100%;
}

.phone {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.phoneLabel {
  width: 40px;
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
}

.phoneField {
  width: 100%;
}

.smsButton {
  text-transform: none;
  color: white;
  border: 0;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  background-color: #FF7F29;
  width: 100%;
  border-radius: 4px;
}
.smsButton[disabled] {
  background-color: #b8b8b8;
}

.confirm {
  display: flex;
}

.checkbox {
  width: 30px;
}

.rulesLink {
  display: inline-block;
  width: 100%;
  color: #8dc63f;
  text-align: center;
}

.redirectTimer {
  margin: 10px;
  font-size: 60px;
  text-align: center;
}
