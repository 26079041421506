.integrationContainer {
  background: linear-gradient(0deg, #c1e8fd 0%, #7cbcfd 25.05%, #2684fd 100%);
  margin-bottom: 20px;
}

.integrationImage {
  width: 100%;
  position: relative;
  bottom: -4px;
}

.title {
  color: white;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 16px;
  padding: 24px 24px 0 24px;
}

.description {
  color: white;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 26px;
  padding: 0 24px;
}

.buttons {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

.successBtn {
  display: flex;
  cursor: pointer;
  background-color: #fff;
  color: #006cfd;
  padding: 12px 18px 10px 22px;
  border-radius: 4px;
  font-weight: bold;
  width: fit-content;
  font-size: 22px;
  line-height: 24px;
  text-decoration: none;
}

.closeBtn {
  margin-top: 16px;
  display: flex;
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  text-decoration: underline;
}
