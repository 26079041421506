.leftIcon {
  margin-top: 0;
}

.menuButton {
  color: #ffffff;
}

.rightElement {
  margin-left: auto;
}