.root {
  color: white;
  border: 0;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  padding: 15px;
  background-color: #FF7F29;
  width: 100%;
  border-radius: 0;
  border-radius: 4px;
}

.wrapper {
  margin: 16px;
}